
//==========  List styles  ==========//

// list item styles
%default-list {
    padding: 0 0 0 $block-padding;
    margin: 0 0 $paragraph-margin;
}

%default-ul {
    list-style: disc;
    @extend %default-list;
}

%default-ol {
    list-style: decimal;
    @extend %default-list;
}

// list item reset
%reset-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}


//==========  Buttons and default styles  ==========//

// base button styles
// extend for different button styles
%button {
    border: $border;
    border-color: $color-third;
    // @include if( $border-radius, ( border-radius: $border-radius ) );
    background: transparent;
    color: $color-third;
    font-family: $font-family-title;
    // font-size: $font-size-body - 0.2;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    padding: ($block-padding / 2) $block-padding;
    transition: $transition;
}

%hover {
    background: $color-third;
    color: $color-white;
    border-color: $color-third-dark;
}

// extend for default .read-more
%read-more {
    @extend %button;
    display: inline-block;
    // padding: ($block-padding * 1/3) ($block-padding * 2/3);

    &:hover {
        @extend %hover;
    }
}

